import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import {ServerConfig} from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'
import { PDFExport } from "@progress/kendo-react-pdf";

import {ALERT_FAIL} from '../../redux/actions/types.js'

import loading_gif from '../../assets/img/loading.gif'
import calrecycle_header from '../../assets/img/calrecycle_header.png'

import Select from 'react-select';
import SortableTbl from "react-sort-search-table";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import PageTemplate from "./pageTemplate";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container, Checkbox, Button } from '@material-ui/core'
import _, { get } from 'lodash'

const useStyles = makeStyles((theme) => ({}))


const CalRecycleMonthlyReport = () => {
    const ref = React.createRef();
    const token = useSelector(state => state.auth.access_token)

    const dispatch = useDispatch();

    const [availableMachines, setAvailableMachines] = useState([])
    const [rcNumbers, setRcNumbers] = useState("")
    const [selectedMachine_ids, setSelectedMachine_ids] = useState([])
    const [dailyCounts, setDailyCounts] = useState([])
    const [dailySummaries, setDailySummaries] = useState([])
    const [transactionsOver25, setTransactionsOver25] = useState([])
    const [scrapTransactions, setScrapTransactions] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    
    const [timeZone, setTimeZone] = useState("America/New_York");

    const [selectedMaterials, setSelectedMaterials] = useState(['alu', 'pete', 'hdpe', 'glass']);

    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles()

    async function fetchMachineList(token){
      const config = {headers:{'Content-Type':'application/json'}}

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/machines`, config);
      const options = [];

      for(let idx in results.data){
        options.push({value:results.data[idx].id, label: results.data[idx].name});
      }
      setAvailableMachines(options)
      setSelectedMachine_ids(options)
    }

    async function request_download(token){
        setIsLoading(true);

      const config = {
        responseType: 'blob',
        headers:{'Content-Type':'application/json', 'Accept': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      }

      if(token){
        config.headers.Authorization = `Bearer ${token}`
      }

      let this_startdate = (1900+startDate.getYear())+"-"+(1+startDate.getMonth())+"-"+(startDate.getDate())

      const machine_ids = [];
      for(let idx in selectedMachine_ids){
        machine_ids.push(selectedMachine_ids[idx].value);
      }

      const body = JSON.stringify({machine_ids: machine_ids, timezone: timeZone, date: this_startdate})

      var filename;
      function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }

      let ret = axios.post(`${ServerConfig.SERVER_URL}/tenant/calrecycle_excel`, body, config)
        .then(response => {
                    console.log("response:");
                   console.log(response);
                  const disposition = response.headers['content-disposition'];
                  filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
                  console.log("filename:"+filename)
                  if (filename.toLowerCase().startsWith("utf-8''")){
                     filename = decodeURIComponent(filename.replace("utf-8''", ''));
                  }else{
                     filename = filename.replace(/['"]/g, '');
                 }
                  return response.data;
               })
               .then(response_data => {
                  let blob = new Blob([response_data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  document.body.appendChild(a); // append the element to the dom
                  a.click();
                  a.remove(); // afterwards, remove the element
               })
               .catch(error => {
                  console.error(error);
               });

               setIsLoading(false);
    }

    async function fetchMachineTransactions(token){
        setIsLoading(true);

        const config = {headers:{'Content-Type':'application/json'}}

        if(token){
          config.headers.Authorization = `Bearer ${token}`
        }

        let this_startdate = (1900+startDate.getYear())+"-"+(1+startDate.getMonth())+"-"+(startDate.getDate())

        const machine_ids = [];
        for(let idx in selectedMachine_ids){
          machine_ids.push(selectedMachine_ids[idx].value);
        }

        const body = JSON.stringify({machine_ids: machine_ids, timezone: timeZone, date: this_startdate})

        let ret = axios.post(`${ServerConfig.SERVER_URL}/tenant/calrecyclemonthly`, body, config)
        .then((response) => {
          // add up wds-bbp materials  -> "number_of_wdsbbp_receipts"
          // "number_of_Multilayer-Pouch_receipts", "number_of_Paperboard-Carton_receipts", "number_of_Bag-in-Box_receipts"

          for (var i in response.data.daily_summaries_transactions){
              response.data.daily_summaries_transactions[i]["number_of_wdsbbp_receipts"] =
                  (response.data.daily_summaries_transactions[i]["number_of_Multilayer-Pouch_receipts"] || 0)
                  + (response.data.daily_summaries_transactions[i]["number_of_Paperboard-Carton_receipts"] || 0)
                  + (response.data.daily_summaries_transactions[i]["number_of_Bag-in-Box_receipts"] || 0);
          }

          setRcNumbers(response.data.cert_ids.join(","));
          setDailyCounts(response.data.daily_summaries_transactions);
          setDailySummaries(response.data.daily_summaries_material);
          setTransactionsOver25(response.data.transactions_over_thres);
          setScrapTransactions(response.data.scrap_transactions);
        })
        .catch((error) => {
          dispatch({
            type: ALERT_FAIL,
            payload:
              error.response.data.message,
          });
        });

        setIsLoading(false);
    }

  
    useEffect(() => {
      fetchMachineList(token)
    }, [token])


    useEffect(() => {
        fetchMachineTransactions(token);

        const interval = setInterval(() => { fetchMachineTransactions(token); }, 5*60*1000); // 5 minutes
        return () => clearInterval(interval);

    }, [selectedMachine_ids, startDate, timeZone, token])

    if(!token){
      return <Redirect to="/login" />
    }


  let col = ["date",
              "total_number_of_receipts", 
              "number_of_Aluminum_receipts",
              "number_of_Glass_receipts",
              "number_of_PETE_receipts",
              "number_of_HDPE_receipts",
              "number_of_PVC_receipts",
              "number_of_LDPE_receipts",
              "number_of_PP_receipts",
              "number_of_PS_receipts",
              "number_of_OTHER_receipts",
              "number_of_Bi-Metal_receipts",
              "number_of_wdsbbp_receipts"
              ];

	let tHead = ["Date",
                "Total Number of Receipts",
                "Aluminum",
                "Glass",
                "PET #1",
                "HDPE #2",
                "PVC #3",
                "LDPE #4",
                "PP #5",
                "PS #6",
                "Other #7",
                "Bimetal",
                "WDS-BBP"
              ];

  let cols_daily_summaries_material = ["date", "material", "basis_for_payment", "total_weight", "refund_rate", "total_refund_value"];
  let titles_daily_summaries_material = ["Date", "Material Type", "Basis for Payment", "Total Weight", "Refund Rate", "Total Refund Value"]

  let cols_transactions_over25 = ["material", "transactions_over_25_lbs", "transactions_over_250_lbs"]
  let titles_transactions_over25 = ["Material", "#Transactions over 25lbs", "#Transactions over 250lbs"]

  let cols_scrap = ['date', 'tnum', 'desc', 'typeweight', 'customer', 'contactinfo']
  let titles_scrap = ['Date', 'Transaction Number', 'Description', 'Type & Weight', 'Customer Name & Account#', 'Customer Contact Info']

    const handleChange = (event) => {
      if (event.target.checked) {
        if (!selectedMaterials.includes(event.target.value)) {
          setSelectedMaterials([...selectedMaterials, event.target.name]);
        }
      } else {
        const newSelectedMaterials = selectedMaterials.filter((entry) => entry !== event.target.name);
        setSelectedMaterials(newSelectedMaterials);
      }
    };

    return (
        <>
        <Button onClick={() => {request_download(token);}} style={{'marginRight':'20px'}}>Download Excel</Button>
	    <Button onClick={() => {if (ref.current) {ref.current.save();}}}>Export PDF</Button>
        {/* NOTE: excluded "sort"-icons of table-headers in scss, by setting display:none for  .k-pdf-export .fas */}
	    <PDFExport
	        scale={0.5}
	        pageTemplate={PageTemplate}
	        forcePageBreak=".page-break"
	        multiPage="true"
	        paperSize="A4"
	        //margin="0.5cm"
	        margin={{top: '0.5cm', bottom: '2.5cm', right: '0.5cm', left: '0.5cm'}}
	        ref={ref}>

      <Grid container spacing={3} style={{'padding':'20px'}}>
	    <Grid xs={12} className="export-elements">
                  <img src={calrecycle_header} style={{'width':'100%'}}/>
	    </Grid>
  		<Grid xs={12}>
                <div style={{'width':'100%', 'backgroundColor': '#00205f', 'color':'white', 'padding':'5px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'10px'}}>
	    	Beverage Container Recycling Pilot Program<br/>
		  Pilot Project Recycler Monthly Report
	        </div>
        </Grid>


          <Grid xs={6} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
            <Typography variant="h2">
              Machines:
            </Typography>

            {availableMachines.length > 0 && <Select
                isMulti
                /*className="form-select"*/
                name="machine"
                id="machine"
                onChange={(event) => {setSelectedMachine_ids(event)}}  // [{value:'', label:''}, ...]
                options={availableMachines}
                defaultValue={availableMachines}
                />
            }

            </Grid>

            <Grid xs={3} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
            <Typography variant="h2">
                Timezone
              </Typography>
              <select
                className="form-select"
                name="timezone"
                id="timezone"
                onChange={(event) => {setTimeZone(event.target.value)}}>
                  <option value="America/New_York">New York</option>
                  <option value="America/Los_Angeles">California</option>
                </select>
            </Grid>

            <Grid xs={3} style={{'marginBottom': '20px'}}>
              <Typography variant="h2">
                Report Month:
              </Typography>
              <DatePicker showMonthYearPicker dateFormat="MMMM yyyy" selected={startDate} onChange={(date) => {setStartDate(date)}} 
                className="form-select" />
            </Grid>

            {/* RC-number (cert-ids) */}
            <Grid xs={12}>
                <Typography variant="h2">
                Pilot Project Recycler Certification Number: {rcNumbers}<br/>
                </Typography>
              </Grid>

            {/* Daily counts */}
            <Grid xs={12} style={{'border':'1px solid gray', 'marginBottom':'20px'}}>
  		<Grid xs={12}>
                <div style={{'width':'100%', 'backgroundColor': '#00205f', 'color':'white', 'padding':'5px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'10px'}}>
	    	Daily Counts	
	    </div>
	    </Grid>

              <Grid xs={12} style={{'textAlign':'center', 'marginTop':'20px'}}>
                {!isLoading &&
                  <SortableTbl
                  paging={false}
                  defaultCSS={false}
                  tblData={dailyCounts}
                  tHead={tHead}
                  dKey={col}
                  search={false}
                  sortable={false}
                />
                }
                {isLoading &&
                  <img src={loading_gif} style={{'width':'150px'}}/>
                }
              </Grid>
            </Grid>

		<p className="page-break" />
	  <Grid xs={12} className="export-elements">
                  <img src={calrecycle_header} style={{'width':'100%'}}/>
	  </Grid>


            {/* Daily summaries per material */}
            <Grid xs={12} style={{'border':'1px solid gray', 'marginBottom':'20px'}}>
              <Grid xs={12}>
                <div style={{'width':'100%', 'backgroundColor': '#00205f', 'color':'white', 'padding':'5px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'10px'}}>
                Recap of daily summaries</div>
              </Grid>
              <Grid xs={12} style={{'textAlign':'center', 'marginTop':'20px'}}>
                {!isLoading &&
                  <SortableTbl
                  paging={false}
                  defaultCSS={false}
                  tblData={dailySummaries}
                  tHead={titles_daily_summaries_material}
                  dKey={cols_daily_summaries_material}
                  search={false}
                />
                }
                {isLoading &&
                  <img src={loading_gif} style={{'width':'150px'}}/>
                }
              </Grid>
            </Grid>


		<p className="page-break" />
	  <Grid xs={12} className="export-elements">
                  <img src={calrecycle_header} style={{'width':'100%'}}/>
	  </Grid>


            {/* Transactions over 25lbs */}
            <Grid xs={12} style={{'border':'1px solid gray', 'marginBottom':'20px'}}>
              <Grid xs={12}>
                <div style={{'width':'100%', 'backgroundColor': '#00205f', 'color':'white', 'padding':'5px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'10px'}}>
                Transactions with over 25lbs of material</div>
              </Grid>
              <Grid xs={12} style={{'textAlign':'center', 'marginTop':'20px'}}>
                {!isLoading &&
                  <SortableTbl
                  paging={false}
                  defaultCSS={false}
                  tblData={transactionsOver25}
                  tHead={titles_transactions_over25}
                  dKey={cols_transactions_over25}
                  search={false}
                />
                }
                {isLoading &&
                  <img src={loading_gif} style={{'width':'150px'}}/>
                }
              </Grid>
            </Grid>

          {/* Scrap */}
            <Grid xs={12} style={{'border':'1px solid gray', 'marginBottom':'20px'}}>
              <Grid xs={12}>
                <div style={{'width':'100%', 'backgroundColor': '#00205f', 'color':'white', 'padding':'5px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'10px'}}>
                Line Breakage, Previously Redeemed/Baled, Out-of-State Material</div>
              </Grid>
              <Grid xs={12} style={{'textAlign':'center', 'marginTop':'20px'}}>
                {!isLoading &&
                  <SortableTbl
                  paging={false}
                  defaultCSS={false}
                  tblData={scrapTransactions}
                  tHead={titles_scrap}
                  dKey={cols_scrap}
                  search={false}
                />
                }
                {isLoading &&
                  <img src={loading_gif} style={{'width':'150px'}}/>
                }
              </Grid>

	    </Grid>


            </Grid>
	    </PDFExport>
      </>
    )
  }
  
  export default CalRecycleMonthlyReport

  
