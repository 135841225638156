import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import _, { get } from 'lodash'

import { Typography, Grid, Checkbox } from '@material-ui/core'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Line, Bar } from '@reactchartjs/react-chart.js';
import SortableTbl from "react-sort-search-table";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'

let materials_and_abbreviations = [
        ['alu', 'Aluminum', 'Alu', 'rgb(151, 215, 0)'],
        ['pete', 'PETE', 'PETE', 'rgb(0, 142, 170)'],
        ['glass', 'Glass', 'Glass', 'rgb(220, 220, 220)'],
        ['bi-metal', 'Bi-Metal', 'Bi-M', 'rgb(120, 120, 120)'],
        ['hdpe', 'HDPE', 'HDPE', 'rgb(0, 79, 113)'],
        ['ldpe', 'LDPE', 'LDPE', 'rgb(255, 200, 69)'],
        ['pp', 'PP', 'PP', 'rgb(222, 124, 0)'],
        ['ps', 'PS', 'PS', 'rgb(137, 59, 103)'],
        ['other', 'OTHER', 'OTHER', 'rgb(190, 77, 0)'],
        ['bib', 'Bag-in-Box', 'BiB', 'rgb(52, 167, 152)'],
        ['pc', 'Paperboard-Carton', 'PC', 'rgb(143, 106, 42)'],
        ['mp', 'Multilayer-Pouch', 'MP', 'rgb()'],
        ]

const plot_options = {
    maintainAspectRatio: false,
    legend: {
        display: true
    },
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: { zeroLineColor: 'gray', color: 'rgba(128,128,128,0.3)', display: true}
      },
    ],
    xAxes: [{
        type: 'time',
        time: {
            unit: 'month'
        },
        offset: true,
        type: "category",
        gridLines: { zeroLineColor: 'gray', display:false}
    }]
  },
};

const RecyclableStats = () => {
  const token = useSelector(state => state.auth.access_token)

  let date_before_one_months = new Date();
  //date_before_one_months.setMonth(date_before_one_months.getMonth() - 1); // use less days
  date_before_one_months.setDate(1);  // set start-date to first of month

  const [startDate, setStartDate] = useState(date_before_one_months);
  const [endDate, setEndDate] = useState(new Date());
  const [timeZone, setTimeZone] = useState("America/New_York");

  const [countsTotal, setCountsTotal] = useState([])
  const [countsTotalForTable, setCountsTotalForTable] = useState([])

  const [countsPerMaterial, setCountsPerMaterial] = useState([])
  const [countsPerMaterialForTable, setCountsPerMaterialForTable] = useState([])

  const [totalGraphData, setTotalGraphData] = useState([])
  const [totalWeightsGraphData, setTotalWeightsGraphData] = useState([])
  const [perMaterialGraphData, setPerMaterialGraphData] = useState([])
  const [perMaterialWeightGraphData, setPerMaterialWeightGraphData] = useState([])

  async function fetchStats(token){
    const config = {headers:{'Content-Type':'application/json'}}

    if(token){
      config.headers.Authorization = `Bearer ${token}`
    }

    let this_startdate = (1900+startDate.getYear())+"-"+(1+startDate.getMonth())+"-"+(startDate.getDate())
    let this_enddate = (1900+endDate.getYear())+"-"+(1+endDate.getMonth())+"-"+(endDate.getDate())

    const results = await axios.post(`${ServerConfig.SERVER_URL}/tenant/stats_recyclables`, {timezone: timeZone, startdate: this_startdate, enddate: this_enddate}, config)
    //const results = await axios.get(`${ServerConfig.SERVER_URL}/tenant/stats_container_counts`, config);
    /*
      "per_date": {
      "counts": [
        5036,
        3398,
        26575
      ],
      "weights": [
        0.0,
        0.0,
        3.5
      ],
      "dates": [
        "2024-11",
        "2024-12",
        "2025-01"
      ]
    },
    */
    setCountsTotal(results.data["per_date"]);

    // "flatten" to display in SortableTbl
    let transformed_data = [];
    for(let i=0; i < results.data["per_date"]["counts"].length;i++){
      transformed_data.push({'date': results.data["per_date"]["dates"][i], 'count': results.data["per_date"]["counts"][i], 'weight': results.data["per_date"]["weights"][i]+"lbs"});
    }
    setCountsTotalForTable(transformed_data);

    /*
      "per_date_material": {
      "2024-11": {
        "Aluminum": {
          "co2_saved_g": 5021,
          "count": 2231
        },
        "Bag-in-Box": {
          "co2_saved_g": 0,
          "count": 17
        },
        */
     setCountsPerMaterial(results.data["per_date_material"])

     // "flatten" to display in SortableTbl
    transformed_data = [];
    for(let i in results.data["per_date_material"]){
      let this_item = {'date': i};
      for(let j in materials_and_abbreviations){
        if(materials_and_abbreviations[j][1] in results.data["per_date_material"][i]){
          this_item['count_'+materials_and_abbreviations[j][0]] = results.data["per_date_material"][i][materials_and_abbreviations[j][1]]["count"]
          this_item['weight_'+materials_and_abbreviations[j][0]] = results.data["per_date_material"][i][materials_and_abbreviations[j][1]]["weight"]
        }else{
          this_item['count_'+materials_and_abbreviations[j][0]] = 0
          this_item['weight_'+materials_and_abbreviations[j][0]] = 0
        }
      }
      transformed_data.push(this_item);
    }
    setCountsPerMaterialForTable(transformed_data);

    // graph data preparation, first the total graph:
    var total_graph_labels = results.data["per_date"]["dates"];
    var total_graph_data = results.data["per_date"]["counts"];

    var total_graph_datasets = [
      {
        label: '#Recyclables/Month',
        data: total_graph_data,
        fill: false,

        backgroundColor: 'rgb(151, 215, 0)',
        borderColor: 'rgba(151, 215, 0, 0.4)',
        yAxisID: 'y-axis-1',
      },
      ]
    setTotalGraphData({labels: total_graph_labels, datasets: total_graph_datasets});

    // graph data preparation, total weights:
    var total_weights_graph_labels = results.data["per_date"]["dates"];
    var total_weights_graph_data = results.data["per_date"]["weights"];

    var total_weights_graph_datasets = [
      {
        label: 'lbs Recyclables/Month',
        data: total_weights_graph_data,
        fill: false,

        backgroundColor: 'rgb(151, 215, 0)',
        borderColor: 'rgba(151, 215, 0, 0.4)',
        yAxisID: 'y-axis-1',
      },
      ]
    setTotalWeightsGraphData({labels: total_weights_graph_labels, datasets: total_weights_graph_datasets});

    // and the per-date-and-material graph:
    let material_graph_labels = [];
    let material_graph_data = {};
    for(let j in materials_and_abbreviations){
      material_graph_data["counts_"+materials_and_abbreviations[j][0]] = [];
      material_graph_data["weights_"+materials_and_abbreviations[j][0]] = [];
    }

    for(let i in transformed_data){
      material_graph_labels.push(transformed_data[i]["date"]);
      for(let j in materials_and_abbreviations){
        if("count_"+materials_and_abbreviations[j][0] in transformed_data[i]){
          material_graph_data["counts_"+materials_and_abbreviations[j][0]].push(transformed_data[i]["count_"+materials_and_abbreviations[j][0]]);
          material_graph_data["weights_"+materials_and_abbreviations[j][0]].push(transformed_data[i]["weight_"+materials_and_abbreviations[j][0]]);
        }else{
          material_graph_data["counts_"+materials_and_abbreviations[j][0]].push(0);
          material_graph_data["weights_"+materials_and_abbreviations[j][0]].push(0);
        }
      }
    }

    // prepare counts per material per month graph
    var per_material_graph_datasets = [];
    for(let j in materials_and_abbreviations){
        per_material_graph_datasets.push(
          {
            label: materials_and_abbreviations[j][1],
            data: material_graph_data["counts_"+materials_and_abbreviations[j][0]],
            fill: false,

            backgroundColor: materials_and_abbreviations[j][3],
            yAxisID: 'y-axis-1',
          }
        )
      }
    setPerMaterialGraphData({labels: material_graph_labels, datasets: per_material_graph_datasets});

    // prepare WEIGHT per material per month graph
    var per_material_weights_graph_datasets = [];
    for(let j in materials_and_abbreviations){
        per_material_weights_graph_datasets.push(
          {
            label: materials_and_abbreviations[j][1],
            data: material_graph_data["weights_"+materials_and_abbreviations[j][0]],
            fill: false,

            backgroundColor: materials_and_abbreviations[j][3],
            yAxisID: 'y-axis-1',
          }
        )
      }
    setPerMaterialWeightGraphData({labels: material_graph_labels, datasets: per_material_weights_graph_datasets});
  }

  useEffect(() => {
    fetchStats(token)
  }, [startDate, endDate, timeZone, token])

  let col_total_table = ["date", "count", "weight"];
  let hdr_total_table = ["Date", "Total count", "Total weight"];

  let col_cnt_per_material_table = ["date"];
  let hdr_cnt_per_material_table = ["Date"]
  let col_weight_per_material_table = ["date"];
  let hdr_weight_per_material_table = ["Date"]
  for(let j in materials_and_abbreviations){
    col_cnt_per_material_table.push("count_"+materials_and_abbreviations[j][0]);
    hdr_cnt_per_material_table.push("#"+materials_and_abbreviations[j][2]);
    col_weight_per_material_table.push("weight_"+materials_and_abbreviations[j][0]);
    hdr_weight_per_material_table.push("lbs "+materials_and_abbreviations[j][2]);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={3} style={{'margin-bottom': '20px', 'padding-right':'20px'}}>
          <Typography variant="h2">
            Timezone
          </Typography>
          <select
            className="form-select"
            name="timezone"
            id="timezone"
            onChange={(event) => {setTimeZone(event.target.value)}}>
              <option value="America/New_York">New York</option>
              <option value="America/Los_Angeles">California</option>
          </select>
        </Grid>

        <Grid xs={3} style={{'marginBottom': '20px'}}>
          <Typography variant="h2">
            Start-Date (incl.):
          </Typography>
          <DatePicker
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            selected={startDate}
            onChange={(date) => {setStartDate(date)}}
            className="form-select" />
        </Grid>

        <Grid xs={3} style={{'marginBottom': '20px'}}>
          <Typography variant="h2">
            End-Date (incl.):
          </Typography>
          <DatePicker
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            selected={endDate}
            onChange={(date) => {setEndDate(date)}}
            className="form-select" />
        </Grid>
    </Grid>

    <Card variant="outlined" style={{'marginLeft':'-12px', 'marginBottom':'20px'}}>
      <CardContent>
      <Grid container spacing={3} style={{'marginTop':'20px'}}>
        <Grid item xs={12} sm={4}>
          <Typography color="primary" variant="h2">
            Total recyclables per month
          </Typography>
          <SortableTbl paging={false} defaultCSS={false}
                tblData={countsTotalForTable} tHead={hdr_total_table} dKey={col_total_table} search={false} />
        </Grid>

        <Grid item xs={12} sm={4} style={{'marginBottom': '20px', 'maxHeight':'200px'}}>
          <Typography color="primary" variant="h2">
            Total recyclable counts per month
          </Typography>

          <Bar data={totalGraphData} options={plot_options} resizeDelay={0} />
        </Grid>

        <Grid item xs={12} sm={4} style={{'marginBottom': '20px', 'maxHeight':'200px'}}>
          <Typography color="primary" variant="h2">
            Total recyclable weights per month
          </Typography>

          <Bar data={totalWeightsGraphData} options={plot_options} resizeDelay={0} />
        </Grid>
      </Grid>
      </CardContent>
    </Card>

    <Card variant="outlined" style={{'marginLeft':'-12px', 'marginBottom':'20px'}}><CardContent>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h2">
          Total recyclable counts per month and material
        </Typography>
        <SortableTbl paging={false} defaultCSS={false}
              tblData={countsPerMaterialForTable} tHead={hdr_cnt_per_material_table} dKey={col_cnt_per_material_table} search={false} />
      </Grid>

      <Grid item xs={12} style={{'marginBottom': '20px', 'maxHeight':'200px'}}>
        <Typography color="primary" variant="h2">
          Recyclables counts per month and material
        </Typography>

        <Bar data={perMaterialGraphData} options={plot_options} resizeDelay={0} />
      </Grid>
    </Grid>
    </CardContent></Card>

    <Card variant="outlined" style={{'marginLeft':'-12px', 'marginBottom':'20px'}}><CardContent>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography color="primary" variant="h2">
          Total recyclable weights per month and material
        </Typography>
        <SortableTbl paging={false} defaultCSS={false}
              tblData={countsPerMaterialForTable} tHead={hdr_weight_per_material_table} dKey={col_weight_per_material_table} search={false} />
      </Grid>

      <Grid item xs={12} style={{'marginBottom': '20px', 'maxHeight':'200px'}}>
        <Typography color="primary" variant="h2">
          Recyclables weights per month and material
        </Typography>

        <Bar data={perMaterialWeightGraphData} options={plot_options} resizeDelay={0} />
      </Grid>
    </Grid>
    </CardContent></Card>
    </>
  )
}

export default RecyclableStats
