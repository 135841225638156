import React from 'react'
import { ServerConfig } from 'config'

import List from '../../components/EntryListAndEdit/List'


const ScrapRefundrates = () => {
  const editable_fields = [
    ["validity_start", "Validity Start", "date"],
    ["validity_end", "Validity End", "date"],
    ["material", "Material", "material"],
    ["dollar_per_pound", "$/lbs", "int"],
    ["daily_weight_limit_lbs", "Limit lbs/day", "int"]
  ];

  return (
    <>
      <List
        list_get_url={`${ServerConfig.SERVER_URL}/tenant/scrap_refundrates`}
        detail_get_url={`${ServerConfig.SERVER_URL}/tenant/scrap_refundrates/`}
        detail_put_url={`${ServerConfig.SERVER_URL}/tenant/scrap_refundrates/`}
        add_new_url={`${ServerConfig.SERVER_URL}/tenant/scrap_refundrates`}

        column_names={["validity_start", "validity_end", "material", "dollar_per_pound", "daily_weight_limit_lbs", "edit"]}
        header_names={["Valid from", "Valid until", "Material", "$/lbs", "Limit lbs/day", ""]}

        editable_fields={editable_fields}
        //ledger_get_url = '/ledger/customer/'
        //show_machine_stats={true}
      />
    </>
  )
}

export default ScrapRefundrates
